

















import { defineComponent } from '@vue/composition-api'
import Coupon from '@/components/Coupon.vue'

export default defineComponent({
  components: { Coupon },
})
